<template>
  <div class="Batchline">
    <div class="minw homeDivCss">
      <div class="bottom">
        <!-- 筛选条件 -->
        <div class="condition">
          <div class="searchTitle searchTitleLater">
            年份
          </div>
          <div style="flex: 47" >
            <span v-for="(item, index) in yearList" @click="yearchange(item)" :class="{ active: queryParams.year == item }">{{
              item }}</span>
          </div>
        </div>
        <div class="condition">
            <div class="searchTitle searchTitleLater">
              选科
            </div>
            <div style="flex: 47" >
              <span v-for="(item, index) in subjectList" @click="subjectchange(item)" :class="{ active: queryParams.type == item   }">{{
                item }}</span>
            </div>
          </div>
          <div class="condition">
              <div class="searchTitle searchTitleLater">
                批次
              </div>
              <div style="flex: 47" >
                <span v-for="(item, index) in BatchlineType" @click="batchchange(item.dictLabel)" :class="{ active: queryParams.batch == item.dictLabel   }">{{
                  item.dictLabel }}</span>
              </div>
            </div>
            <div class="condition">
                <div class="searchTitle">
                  共匹配到  <span style="color: #12B098;">{{ queryParams.total }}</span>  条结果
                </div>
            </div>
        <div>
          <el-table :data="tableData"  class="threeTable"
            :header-cell-style="{
              backgroundColor: '#f4f4f4',
              color: 'black',
              fontSize: '17px',
              textAlign: 'center',
            }" :cell-style="{
  textAlign: 'center',

}" v-loading="loading"  :row-class-name="tableRowClassName">

            <el-table-column prop="year" label="年份" width="180">
            </el-table-column>
            <el-table-column prop="batch" label="批次"></el-table-column>
            <el-table-column prop="type" label="选科类别"></el-table-column>
            <el-table-column prop="socre" label="分数线"></el-table-column>
            <el-table-column prop="majorsocre" label="专业分"></el-table-column>

          </el-table>
          <pagination v-show="queryParams.total > 0" :total="queryParams.total" :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize" @pagination="onload()" />

        </div>
        <!-- <div
          v-for="(item, index) in tableData"
          class="XiaoXibox"
          :key="index"
          @click="godetail(item)"
        >
          <div :style="{ color: index == 0 ? '#30C9B2' : '' }">
            <p></p>
            <h2>{{ item.titleMain }}</h2>
            <h3>{{ item.titleFu }}</h3>
          </div>
          <span>{{ item.time }}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { REGlistPage } from "@/api/homeIndex.js"
export default {
  name: "Batchline",
  data() {
    return {
      loading: true,
      tableData: [
        {
          province: "河北",
          year: "2022",
          batch: "本科",
          type: "物理",
          socre: "452",
          majorsocre: "340",
        }
      ],
      queryParams: {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        province: '河北',
        year: '不限',
        batch: '不限',
        type: '不限',
        socre: '',
        majorsocre: '',
      },
      BatchlineType: [],
      yearList:[],
      subjectList:['不限','物理类','历史类',]
    };
  },
  created() {

    for (let index = 0; index < 5; index++) {
          let item = new Date().getFullYear() - index
          this.yearList.push(item)
    }
      this.yearList.unshift('不限')
     let serverTime = new Date(this.$store.state.serverTime)
     // 系统时间小于6.25展示去年，反之展示今年。
    // if ( serverTime.getMonth() < 5) {
    //   this.queryParams.particularYear =  serverTime.getFullYear() - 1;
    // }else if ( serverTime.getMonth() == 5){
    //   if ( serverTime.getDate() <= 25) {
    //     this.queryParams.particularYear = serverTime.getFullYear() - 1;
    //   } else {
    //     this.queryParams.particularYear =  serverTime.getFullYear()
    //   }
    // } else {
    //   this.queryParams.particularYear =  serverTime.getFullYear()
    // }
    this.onload()   //获取数据列表
    this.getBatchType()     //获取批次类型
  },
  methods: {
    yearchange(item){
        this.queryParams.year = item;
        this.onload()
    },
    subjectchange(item){
        this.queryParams.type = item
        this.onload()
    },
    batchchange(dictLabel){
        this.queryParams.batch = dictLabel
        this.onload()
    },
    tableRowClassName({row, rowIndex}) {
        const arr = [0,2,4,6,8,10]
        if (arr.includes(rowIndex)) {
          return 'oddTable';
        } else  {
          return 'evenTable';
        }
        return '';
      },
    onload() {
      const self = this;
      self.loading = true
      let obj = {...this.queryParams}
      if(obj.year == '不限'){
          obj.year = ''
      }
      if(obj.type == '不限'){
        obj.type = ''
      }
       if(obj.batch == '不限'){
        obj.batch = ''
      }

      REGlistPage(obj).then(res => {
        if (res.code == 200) {
          res.rows.forEach(item=>{
            if(item.majorsocre == 0){
              item.majorsocre = '--'
            }
          })
          self.tableData = res.rows;
          self.queryParams.total = res.total;
          self.loading = false
        }
      })
    },
    getBatchType() {
      const self = this;
      this.getDicts('sys_batchline_type').then(res => {
        if (res.code == 200) {
          res.data.unshift({dictLabel:'不限'})
          self.BatchlineType = res.data
        }
      })
    },
    search() {
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 10;
      this.onload()
    },
    reset() {
      this.queryParams = {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        province: '河北',
        year: '',
        batch: '',
        type: '',
        socre: '',
        majorsocre: '',
      }
      this.onload()
    },
    // godetail(item) {
    //   this.$router.push({
    //     path: "/batchDetail",
    //   });
    // },
  },
};
</script >

<style lang="scss" scoped>
.Batchline {
  user-select: none;

  // background: rgb(252, 251, 249);
  // padding-bottom: 100px;
  .homeDivCss .bottom {
    height: 805px;
    overflow-y: scroll;
  }

  .homeDivCss .bottom::-webkit-scrollbar {
    display: none;
  }

  .bottom {
    // ::v-deep .el-table .oddTable{
    //   background: white;
    // }
    // ::v-deep .el-table .evenTable{
    //   background: #EEEEEE;
    // }
    .active {
      background: #12B098;
      border-radius: 8px;
      color: white;
    }

    .active:hover {
      color: white;
    }

    .XiaoXibox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      padding: 25px 20px;
      margin-bottom: 20px;
      cursor: pointer;
      font-size: 20px;

      div:nth-of-type(1):hover {
        color: #30C9B2;
      }

      p {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        background: #30C9B2;
        margin-right: 10px;
      }

      h2 {
        display: inline-block;
      }
    }
  }
}</style>
